<template>
    <div class="game-main">
        <div class="game-mock">
            <div class="result-title">
                <h2>泌尿青年医师手术&病例大会</h2>
                <i>{{ title }}</i>
            </div>
            <div class="game-box">
                <div class="game-left">
                    <h3 class="game-l-title">抽奖活动</h3>
<!--                    <div class="game-l-select">-->
<!--                        <select v-model="selValue" @change="changeJx">-->
<!--                            <option v-for="(item,index) in selectList" :value="item.id">{{ item.name }}</option>-->
<!--                        </select>-->
<!--                    </div>-->
                    <div class="game-l-result">
                        <template v-for="(item,index) in phone" v-if="index<3">
                            <p :key="index">
                                {{ item | filtersPhone }}
                            </p>
                        </template>
                    </div>
                    <div class="game-l-btn" :class="isDraw? 'end':'start'" @click="draw">{{ btnTxt }}</div>
                </div>
                <div class="game-right">
                    <div class="game-draw-list">
                        <div class="game-draw-list-item">
                            <h3>便携式折叠风扇<br/><span>获奖观众：</span></h3>
                            <ul>
                                <li style="height: 250px;width: 400px" v-if="list1.length>0">
                                    <p v-for="item in list1" style="display: inline-block;width: 50%">{{ item | filtersPhone }}</p>
                                    <p v-for="item in list2" style="display: inline-block;width: 50%">{{ item | filtersPhone }}</p>
                                </li>
                                <li v-if="list1.length<=0">等待抽奖</li>
                            </ul>
                        </div>
<!--                        <div class="game-draw-list-item">-->
<!--                            <h3>二等奖(泌尿腹腔镜与机器人手术专业书籍1本)<br/><span>获奖观众：</span></h3>-->
<!--                            <ul>-->
<!--                                <li v-for="item in list2">{{ item | filtersPhone }}</li>-->
<!--                                <li v-if="list2.length<=0">等待抽奖</li>-->
<!--                            </ul>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "lucky-draw",
        data() {
            return {
                selectList: [{id: 1,name: '一等奖'},{id: 2,name: '二等奖'}],
                selValue: 1,
                isDraw: false, // 是否在抽奖
                btnTxt: '开始抽奖',
                phone: [
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                ],

                list1:[],
                list2: [],
                interval: null,

                list: [
					'17260041163','13318401801','15273849542','17306535778','17769841233','19845176342','18425888752','13166835386',
					'17370456798','18212391248','15269743362','18420718957','17865526507','17634165549','15720685750','13212923187',
					'19830353578','17243928323','18043558004','13462415533','13782959407','16526481547','17191118591','18768998293',
					'15613119480','13640962144','17516822531','15757897942','15996789832','18561477493','17574471117','13463291854',
					'13546911268','15269325750','15965918517','17735581108','18252379176','18286942837','16689062393','18584178094',
					'18218013486','15091179926','13733877802','13012326945','14712453423','13079706429','18205415805','15267266557',
					'15557893587','15673276260','17273998345','13416968064','18346213800','17796725058','18747544453','17798002531',
					'16652207648','15364461406','18133653747','18303271325','13751282971','17666092216','18802224272','16623441030',
					'17225769176','14737356844','18809552481','15065578668','18646642803','18666848194','17685309028','13516589635',
					'18583389147','13395166106','15757556136','16572355199','14527905359','18404059186','14534371563','13830607716',
					'13339246891','13568607947','15879533017','15323413198','13847725219','13313648988','14913173616','18132143585',
					'15068882526','17562126532','15275817361','18593757146','18588085760','15973758443','15710223107','18103766228',
					'18193347723','18974372508','14785033883','13624569076'
                ],
                title: ''
            }
        },
        filters: {
            filtersPhone(val) {
                return val.substring(0,3) + '****' + val.substring(7,11)
            }
        },
        mounted() {
            this.$axios.post(this.apiUrl.baiji23GroupStatus,{group_id:this.$route.query.group_id,live_id:this.$route.query.live_id}).then(res => {
				if(res.code==1){
					this.title = res.data.group_name
				}else{
					this.title = res.msg
				}
            })
        },
        methods: {
            changeJx(e) {
                // console.log(this.selValue)
                // if(this.selValue == 1) {
                //     this.phone = ['***********']
                // }else {
                //     this.phone = [
                //         '***********',
                //         '***********',
                //         '***********',
                //     ]
                // }
                this.phone = [
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                ]
            },
            draw() {
                // if(this.selValue == 1 && this.list1.length > 0) {
                //     this.$message.info('一等奖已抽完')
                //     return false;
                // }
                // if(this.selValue == 2 && this.list2.length > 0){
                //     this.$message.info('二等奖已抽完')
                //     return false;
                // }
                if(this.list1.length > 0) {
                    this.$message.info('奖项已抽完')
                    return false;
                }
                if(!this.isDraw) {
                    /* 开始抽奖 */
                    this.isDraw = true;
                    this.btnTxt = '结束抽奖';

                    this.interval = setInterval(()=> {
                        for(let i = 0;i<this.phone.length;i++) {
                            this.$set(this.phone, i, this.list[Math.floor((Math.random()*this.list.length))])
                        }
                    }, 100)
                }else {
                    /* 结束抽奖 */
                    this.$axios.post(this.apiUrl.baiji23luckDraw, {
						group_id:this.$route.query.group_id,
						live_id:this.$route.query.live_id
                        // type: this.selValue,
                        // num: this.selValue == 1? 1:3
                    }).then(res => {
                        if(res.code == 1) {
							for(let i = 0;i<this.phone.length;i++) {
								this.$set(this.phone, i, this.getExtract(this.list))
								console.log(this.getExtract(this.list))
							}
                            this.$message.success(res.msg);
                            this.isDraw = false;
                            this.btnTxt = '开始抽奖';
                            clearInterval(this.interval);
                            // this.phone = (res.data.qt+res.data.luckthree).split(',');
                            this.list1 = res.data.phone.split(',');
                            // this.list2 = res.data.luckthree.split(',');
                        }else {
                            this.isDraw = false;
                            clearInterval(this.interval);
                            this.btnTxt = '开始抽奖';
                            this.phone = [
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                                '***********',
                            ]
                            this.$message.info(res.msg)
                        }
                    })
                }
            },
			getExtract(array) {
				const random = (min, max) => Math.floor(Math.random() * (max - min) + min); //随机数方法
				let index=random(0, array.length); //生成一个从最小值为0 最大值为数组长度的随机数
				return array.splice(index, 1)[0];
			}
        }

    }
</script>

<style scoped lang="scss">
    .in-out-translate-fade-enter-active,
    .in-out-translate-fade-leave-active {
        transition: all .5s;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -o-transition: all .5s;
    }
    .in-out-translate-fade-enter,
    .in-out-translate-fade-leave-active {
        opacity: 0;
    }
    .in-out-translate-fade-enter {
        transform: translatex(54px);
        -webkit-transform: translatex(54px);
        -moz-transform: translatex(54px);
        -o-transform: translatex(54px);
    }
    .in-out-translate-fade-leave-active {
        transform: translatex(-54px);
        -webkit-transform: translatex(-54px);
        -moz-transform: translatex(-54px);
        -o-transform: translatex(-54px);
    }
    .game-main{
        height: 100vh;
        background: url("~@/assets/img/baiji/bj2024-1.jpg") no-repeat;
        background-size: 100% 100%;
        .game-mock{/*
            background: rgba(000,000,000,.2);*/
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
        .game-box{
            width: 1300px;
            margin: 0 auto;
            height: 100vh;
            padding: 100px 150px 0;
            box-sizing: border-box;
            display: flex;
            /*background: rgba(255,255,255,.6);*/
        }
        .game-left{
            font-size: 24px;
            flex: 1;
            .game-l-title{
                line-height: 50px;
                text-align: center;
                color: #bf3763;
                width: 250px;
                font-size: 26px;
            }
            .game-l-select{
                select{
                    border-radius: 4px;
                    font-size: 24px;
                    width: 250px;
                    height: 40px;
                    outline: none;
                    -webkit-writing-mode: horizontal-tb !important;
                    text-rendering: auto;
                    color: -internal-light-dark(black, white);
                    letter-spacing: normal;
                    word-spacing: normal;
                    text-transform: none;
                    text-indent: 0px;
                    text-shadow: none;
                    display: inline-block;
                    text-align: start;
                    appearance: menulist;
                    box-sizing: border-box;
                    align-items: center;
                    white-space: pre;
                    -webkit-rtl-ordering: logical;
                    background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
                    cursor: default;
                    margin: 0em;
                    border-width: 1px;
                    border-style: solid;
                    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
                    border-image: initial;
                }
            }
            .game-l-result{
                width: 250px;
                margin: 15px 0;
                border-radius: 4px;
                text-align: center;
                line-height: 36px;
                letter-spacing: 3px;
                font-size: 28px;
            }
            .game-l-btn{
                border: none;
                color: #fff;
                font-size: 18px;
                border-radius: 4px;
                cursor: pointer;
                width: 250px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                &.start{ background-color:#428bca;}
                &.end{ background-color:#d9534f;}
            }
        }
        .game-right{
            .game-draw-list{
                min-height: 330px;
                border-radius: 5px;
                padding: 10px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                .game-draw-list-item{
                    flex: 1;
                    h3{
                        color: #bf3763;
                        font-size: 26px;
                        margin-bottom: 10px;
                        span{
                            margin-top: 10px;
                            display: block;
                        }
                    }
                    ul li{
                        font-size: 26px;
                        color: #0a8cd2;
                    }
                }
            }
        }
    }
    .result-title{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-weight: bold;
        font-family:SimHei;
        color: #bf3763;
        margin-top: 40px;
        h2{
            font-size: 35px;
        }
        i{font-size: 35px;margin: 10px 0}
        h3{
            font-size: 50px;
            text-align: center;
            font-weight: bold;
            font-family: PangMenZhengDao;
            text-transform:uppercase;
            color:#bf3763;
            text-shadow:
                    0px 1px 0px #c0c0c0,
                    0px 2px 0px #C4C4C4,
                    0px 3px 0px #CACACA,
                    0px 4px 0px #CFCFCF,
                    0px 3px 5px rgba(255, 255, 255, 0.7);
            letter-spacing:3px;

        }
    }
</style>
